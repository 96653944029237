import React from 'react'
import downArrow from '../../assets/common/down-arrow.svg';

function EmptySection(props) {

  const { scrollPosition } = props

  return (
    <div>
      {
        scrollPosition >= 900  && <div className='background-ol-empty-about' />
      }
      
      <div className='down-arrow'>
        <img src={downArrow} alt="down-arrow" />
      </div>
    </div>
  )
}

export default EmptySection