import React from 'react';
import designElement from '../../../assets/common/design-element.webp';
import './FifthSection.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

function FifthSectionLower() {
  const { _variants, _duration, _type, _delay } = useSelector(
    (state) => state.transitionProps
  );
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      <div className="background-5-ol-lower" />
      <div className="container">
        <div className="content-section">
          <div className="container">
            <motion.div
              animate={inView ? 'visible' : 'hidden'}
              variants={_variants}
              exit="hidden"
              transition={{ duration: _duration, ease: _type, delay: _delay }}
              ref={ref}
            >
              <div className="section-lower-text">
                <div className="d-flex">
                  <div className="my-auto section-design-element">
                    <img
                      src={designElement}
                      alt="design-element"
                      className="design-element"
                    />
                  </div>
                  <div className="my-auto">
                    <h4 className="section-sub-topic">The call</h4>
                    <h1 className="section-title">This is our call to arms</h1>
                    <div className="col-lg-10 p-0">
                      <p className="section-description">
                        Magharesh cannot fall.
                      </p>
                      <p className="section-description">
                        But we fear that these troubling times may not end with
                        our city. It may be time for an alliance, for heroes and
                        old powers to rise and form a new covenant.
                      </p>
                      <p className="section-description">
                        So take up the sword, the spear, or your most powerful
                        spells and heed the call to battle. For Magharesh!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FifthSectionLower;
