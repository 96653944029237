import React from 'react';
import { useNavigate } from 'react-router-dom';
import designElement from '../../assets/common/design-element-asset.png';
import downArrow from '../../assets/common/down-arrow.svg';
import backIcon from '../../assets/about-section/back.svg';
import divider from '../../assets/about-section/about-section-bottom-ico.svg';
import './AboutSection.css';

function AboutSection(props) {
  const { scrollPosition } = props;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      {scrollPosition >= 0 && scrollPosition <= 1000 && (
        <div className="background-ol-about-us" />
      )}
      <div className="content-section-about about-us-section">
        <div className="container">
          <div
            className="d-flex"
            data-aos="fade-in"
            style={{ cursor: 'pointer' }}
            onClick={() => handleBack()}
          >
            <img
              src={backIcon}
              alt="back-icon"
              className="back-button hide-on-mobile"
            />
            <span className="back-text hide-on-mobile">Back</span>
          </div>
          <div className="text-center" data-aos="fade-in">
            <h1 className="section-title">ABOUT</h1>
            <img
              src={designElement}
              alt="design-element"
              style={{ width: '200px', marginBottom: '10px' }}
              className="text-center"
            />

            <p className="section-description">
              Mindborn Sons is a F2P Team-Based, 3rd Person Battle Arena. rooted
              in South Asian mythology and legends.
            </p>

            <p className="section-description">
              A collaboration between Nine Hermits and Grow Studio.
            </p>

            <p className="section-description">
              The team hopes to expand how the world sees South Asian culture
              and history; prove that a game studio in Sri Lanka can produce a
              world-class game; and most importantly, give players a real sense
              of tribe, community, and ownership.
            </p>

            <p className="section-description">
              Mindborn Sons was created to become more than just a fun
              team-based arena action game. It is also a novel gaming experience
              that introduces unique combat styles and an equally unique world
              of lore.
            </p>

            <img src={divider} alt="about-section-divider" className="mt-4" />

            <br />

            <img
              src={downArrow}
              alt="down-arrow"
              style={{ width: '30px' }}
              className="hide-on-pc mt-3"
            />
          </div>
        </div>
      </div>

      <div className="down-arrow hide-on-mobile">
        <img src={downArrow} alt="down-arrow" />
      </div>
    </div>
  );
}

export default AboutSection;
