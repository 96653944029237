import React from 'react';
import HomeSection from './components/HomeSection/HomeSection'

function Home(props) {

  const { scrollPosition, setScrollPosition } = props;

  return (
    <div>
      <div className="content">
        <HomeSection scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} />
      </div>
    </div>
  );
}

export default Home;
