import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  index: 0
}

export const carsoulActiveSlice = createSlice({
  name: 'activeIndex',
  initialState,
  reducers: {
    setActiveIndex: (state, action) => {
      state.index = action.payload
    }
  }
})

export const { setActiveIndex } = carsoulActiveSlice.actions


export default carsoulActiveSlice.reducer