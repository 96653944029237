import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _variants : {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  },
  _duration: 2,
  _type: 'easeInOut',
  _delay: 0.2
}

export const transitionsSlice = createSlice({
  name: 'transitions',
  initialState,
})


export default transitionsSlice.reducer