import './App.css';
import LayoutView from './LayoutView';
import { ConfigProvider, theme } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

function App() {

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    if(deviceWidth <= 768) {
      window.location.href = 'https://m.mindbornsons.com';
    }
  }, [deviceWidth])
  
  return (
    <div className="App">
      <Router>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
          }}
        >
          <LayoutView />
        </ConfigProvider>
      </Router>
    </div>
  );
}

export default App;
