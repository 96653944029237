import React from 'react';
import './FirstSection.css'

function FirstSectionEmpty(props) {

  const {scrollPosition} = props

  return (
    <div>
      {scrollPosition >= 900 && scrollPosition <= 2100 && <div className='background-ol-empty' />}
    </div>
  );
}

export default FirstSectionEmpty;
