import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loaded: false
}

export const videoLoadingSlice = createSlice({
  name: 'videoLoading',
  initialState,
  reducers: {
    siteLoaded: (state) => {
      state.loaded = true
    }
  }
})

export const { siteLoaded } = videoLoadingSlice.actions


export default videoLoadingSlice.reducer