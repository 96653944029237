import React, { useEffect, useState, useRef } from 'react';
import './HomeSection.css';
import { Controller, Scene } from 'react-scrollmagic';
import outerFixed from '../../assets/common/dial/outer-fixed.png';
import iconSet from '../../assets/common/dial/icon-set.png';
import hellaWordsOuter from '../../assets/common/dial/outer.png';
import hellaWordsInner from '../../assets/common/dial/inner.png';
import intoVid from '../../assets/landing/intro.mov'
import video from '../../assets/video-section/MBS_Web.mov'
import secondSecVid from '../../assets/section-2/02.mov'
import thirdSecVid from '../../assets/section-3/03.mov'
import fourthSecVid from '../../assets/section-4/04.mov'
import fifthSecVid from '../../assets/section-5/05.mov'
import Loading from '../Loading/Loading';
import { useDispatch } from "react-redux"
import { siteLoaded } from '../../Redux/LoadedSlice';

import VideoComponent from '../HomeSectionComponents/VideoSection/VideoComponent';
import LandingSection from '../HomeSectionComponents/LandingSection/LandingSection';
import FirstSectionUpper from '../HomeSectionComponents/FirstSection/FirstSectionUpper';
import FirstSectionLower from '../HomeSectionComponents/FirstSection/FirstSectionLower';
import ThirdSectionUpper from '../HomeSectionComponents/ThirdSection/ThirdSectionUpper';
import ThirdSectionLower from '../HomeSectionComponents/ThirdSection/ThirdSectionLower';
import SecondSectionUpper from '../HomeSectionComponents/SecondSection/SecondSectionUpper';
import SecondSectionLower from '../HomeSectionComponents/SecondSection/SecondSectionLower';
import FourthSectionUpper from '../HomeSectionComponents/FourthSection/FourthSectionUpper';
import FourthSectionLower from '../HomeSectionComponents/FourthSection/FourthSectionLower';
import FifthSectionUpper from '../HomeSectionComponents/FifthSection/FifthSectionUpper';
import FifthSectionLower from '../HomeSectionComponents/FifthSection/FifthSectionLower';
import FirstSectionEmpty from '../HomeSectionComponents/FirstSection/FirstSectionEmpty';
import SecondSectionEmpty from '../HomeSectionComponents/SecondSection/SecondSectionEmpty';
import SecondSectionAssets from '../HomeSectionComponents/SecondSection/SecondSectionAssets';
import ThirdSectionEmpty from '../HomeSectionComponents/ThirdSection/ThirdSectionEmpty';
import ThirdSectionAssets from '../HomeSectionComponents/ThirdSection/ThirdSectionAssets';
import FourthSectionEmpty from '../HomeSectionComponents/FourthSection/FourthSectionEmpty';
import FourthSectionAssets from '../HomeSectionComponents/FourthSection/FourthSectionAssets';
import FifthSectionEmpty from '../HomeSectionComponents/FifthSection/FifthSectionEmpty';
import FooterSectionUpper from '../HomeSectionComponents/FooterSection/FooterSectionUpper';

let loadedPercent = 0;
let touchStartY = 0;

function HomeSection(props) {
  const { scrollPosition, setScrollPosition } = props;
  const dispatch = useDispatch()
  const [isSafari, setIsSafari] = useState(false)

  const imageRef = useRef(null); // image ref for spinning icon-set
  const imageRefOuter = useRef(null); // image ref for hella-words-outer img
  const imageRefInner = useRef(null); // image ref for hella-words-inner img

  // Define the starting and ending scroll positions for the animation
  const startScroll = 5000;
  const endScroll = 20000;
  // Define the rotation angle for each 1000px scroll
  const rotationAngle = 22.5;

  // video loading states
  const [gateVideoLoaded, setGateVideoLoaded] = useState(false)
  const [firstVideoLoaded, setFirstVideoLoaded] = useState(false)
  const [secondVideoLoaded, setSecondVideoLoaded] = useState(false)
  const [thirdVideoLoaded, setThirdVideoLoaded] = useState(false)
  const [fourthVideoLoaded, setFourthVideoLoaded] = useState(false)
  const [fifthVideoLoaded, setFifthVideoLoaded] = useState(false)
  const [fifthVideo2Loaded, setFifthVideo2Loaded] = useState(false)

  const [everyVideoLoaded, setEveryVideoLoaded] = useState(false)
  const [deviceWidth, setDeviceWidth] = useState(null)

  // video refs to handle videos
  const video1Ref = useRef(null);
  const video2Ref = useRef(null);
  const video3Ref = useRef(null);
  const video4Ref = useRef(null);
  const video5Ref = useRef(null);
  const video6Ref = useRef(null);
  const video7Ref = useRef(null);

  useEffect(() => {
    // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    var _isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (_isSafari) {
        setIsSafari(true)
      } else {
        setIsSafari(false)
      }
  }, [])

  useEffect(() => {
    // Get the device width
    var _deviceWidth = window.innerWidth;
    setDeviceWidth(_deviceWidth)
  }, [])

  useEffect(() => {
    if(gateVideoLoaded && firstVideoLoaded && secondVideoLoaded && thirdVideoLoaded && fourthVideoLoaded && fifthVideoLoaded && fifthVideo2Loaded) {
      setEveryVideoLoaded(true)
      dispatch(siteLoaded())
      setTimeout(function() {
        loadedPercent = 0;
      }, 1000);
    } else {
      setEveryVideoLoaded(false)
    }
  }, [gateVideoLoaded, firstVideoLoaded, secondVideoLoaded, thirdVideoLoaded, fourthVideoLoaded, fifthVideoLoaded, fifthVideo2Loaded])

  const handleGateVideoLoad = () => {
    setGateVideoLoaded(true)
    if(loadedPercent <= 100) {
      loadedPercent += 16
    }    
  }

  const handleIntroVideoLoaded = () => {
    setFirstVideoLoaded(true)
    if(loadedPercent <= 100) {
      loadedPercent += 14
    }
  }

  const handleSecondSecVideoLoaded = () => {
    setSecondVideoLoaded(true)
    if(loadedPercent <= 100) {
      loadedPercent += 14
    }
  }

  const handleThirdSecVideoLoaded = () => {
    setThirdVideoLoaded(true)
    if(loadedPercent <= 100) {
      loadedPercent += 14
    }
  }

  const handleFourthVideoLoaded = () => {
    setFourthVideoLoaded(true)
    if(loadedPercent <= 100) {
      loadedPercent += 14
    }
  }

  const handleFifthVideoLoaded = () => {
    setFifthVideoLoaded(true)
    if(loadedPercent <= 100) {
      loadedPercent += 14
    }
  }

  const handleFifthVideo2Loaded = () => {
    setFifthVideo2Loaded(true)
    if(loadedPercent <= 100) {
      loadedPercent += 14
    }
  }

  useEffect(() => {
    if(!everyVideoLoaded) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [everyVideoLoaded])

  useEffect(() => {
    if(deviceWidth >= 768) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollPosition]);

  // responsible for snap scrolling
  useEffect(() => {
    let isScrolling = false;
  
    function handleWheel(event) {
      event.preventDefault();
      if (!isScrolling) {
        const direction = event.deltaY > 0 ? 'down' : 'up';
        if (direction === 'down') {
          const scrollPosition = Math.floor(((window.scrollY + 1000) / 1000)) * 1000
          if (isSafari) {
            smoothScrollTo(scrollPosition + 1);
          } else {
            window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
          }
        } else if (direction === 'up') {
          const scrollPosition = Math.floor(((window.scrollY - 1000) / 1000)) * 1000
          if (isSafari) {
            smoothScrollTo(scrollPosition + 1);
          } else {
            window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
          }
        }
        isScrolling = true;
        setTimeout(() => {
          isScrolling = false;
        }, 2000); // Set this to the amount of time you want to wait before enabling scrolling again
      }
    }
  
    function handleTouchStart(event) {
      touchStartY = event.touches[0].clientY;
    }
  
    function handleTouchMove(event) {
      event.preventDefault();
      if (!isScrolling) {
        const touchEndY = event.touches[0].clientY;
        const direction = touchEndY > touchStartY ? 'up' : 'down';
        if (direction === 'down') {
          const scrollPosition = Math.floor(((window.scrollY + 1000) / 1000)) * 1000
          // smoothScrollTo(scrollPosition + 1);
          window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
        } else if (direction === 'up') {
          const scrollPosition = Math.floor(((window.scrollY - 1000) / 1000)) * 1000
          // smoothScrollTo(scrollPosition + 1);
          window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
        }
        isScrolling = true;
        setTimeout(() => {
          isScrolling = false;
        }, 2000); // Set this to the amount of time you want to wait before enabling scrolling again
      }
    }
  
    function smoothScrollTo(targetPosition) {
      let currentPosition = window.scrollY ;
      const distance = targetPosition - currentPosition;
      const duration = 300;
      let start;
  
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        const elapsed = timestamp - start;
        const progress = elapsed / duration;
  
        window.scrollTo(0, currentPosition + distance + 1 * easeOutQuart(progress));
  
        if (elapsed < duration) {
          window.requestAnimationFrame(step);
        } else {
          document.body.style.overflow = "";
          isScrolling = false;
        }
      });
  
      function easeOutQuart(x) {
        return 1 - Math.pow(1 - x, 4);
      }
    }
  
    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('touchstart', handleTouchStart, { passive: false });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
  
    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  // useEffect(() => {
  //   let isScrolling = false;
  
  //   function handleWheel(event) {
  //     event.preventDefault();
  //     if (!isScrolling) {
  //       const direction = event.deltaY > 0 ? 'down' : 'up';
  //       if (direction === 'down') {
  //         const scrollPosition = Math.floor(((window.scrollY + 1000) / 1000)) * 1000
  //         console.log(scrollPosition)
  //         if (isSafari) {
  //           smoothScrollTo(scrollPosition + 1);
  //         } else {
  //           window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
  //         }
  //       } else if (direction === 'up') {
  //         const scrollPosition = Math.floor(((window.scrollY - 1000) / 1000)) * 1000
  //         console.log(scrollPosition)
  //         if (isSafari) {
  //           smoothScrollTo(scrollPosition + 1);
  //         } else {
  //           window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
  //         }
  //       }
  //       isScrolling = true;
  //       setTimeout(() => {
  //         isScrolling = false;
  //       }, 2000); // Set this to the amount of time you want to wait before enabling scrolling again
  //     }
  //   }
  
  //   function handleTouchStart(event) {
  //     touchStartY = event.touches[0].clientY;
  //   }
  
  //   function handleTouchMove(event) {
  //     if (!isScrolling) {
  //       event.preventDefault();
  //       const touchEndY = event.touches[0].clientY;
  //       const direction = touchEndY > touchStartY ? 'up' : 'down';
  //       if (direction === 'down') {
  //         const scrollPosition = Math.floor(((window.scrollY + 1000) / 1000)) * 1000
  //         console.log(scrollPosition)
  //         if (isSafari) {
  //           smoothScrollTo(scrollPosition + 1);
  //         } else {
  //           window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
  //         }
  //       } else if (direction === 'up') {
  //         const scrollPosition = Math.floor(((window.scrollY - 1000) / 1000)) * 1000
  //         console.log(scrollPosition)
  //         if (isSafari) {
  //           smoothScrollTo(scrollPosition + 1);
  //         } else {
  //           window.scrollTo({ top: scrollPosition + 1, behavior: 'smooth' });
  //         }
  //       }
  //       isScrolling = true;
  //       setTimeout(() => {
  //         isScrolling = false;
  //       }, 2000); // Set this to the amount of time you want to wait before enabling scrolling again
  //     }
  //   }
  
  //   function smoothScrollTo(targetPosition) {
  //     let currentPosition = window.scrollY ;
  //     const distance = targetPosition - currentPosition;
  //     const duration = 300;
  //     let start;
  
  //     window.requestAnimationFrame(function step(timestamp) {
  //       if (!start) start = timestamp;
  //       const elapsed = timestamp - start;
  //       const progress = elapsed / duration;
  
  //       window.scrollTo(0, currentPosition + distance + 1 * easeOutQuart(progress));
  
  //       if (elapsed < duration) {
  //         window.requestAnimationFrame(step);
  //       } else {
  //         document.body.style.overflow = "";
  //         isScrolling = false;
  //       }
  //     });
  
  //     function easeOutQuart(x) {
  //       return 1 - Math.pow(1 - x, 4);
  //     }
  //   }
  
  //   let touchStartY = 0;
  //   window.addEventListener('wheel', handleWheel, { passive: false });
  //   window.addEventListener('touchstart', handleTouchStart, { passive: false });
  //   window.addEventListener('touchmove', handleTouchMove, { passive: false });
  
  //   return () => {
  //     window.removeEventListener('wheel', handleWheel);
  //     window.removeEventListener('touchstart', handleTouchStart);
  //     window.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);

  const handleScroll = () => {
    // const rotationIcons = `rotate(${(16000 - scrollPosition) / 40}deg)`;
    const rotationOuter = `rotate(${-scrollPosition / 10}deg)`;
    const rotationInner = `rotate(${scrollPosition / 5}deg)`;

    // Calculate the rotation angle based on the current scroll position
    if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
      const pixelsScrolled = scrollPosition - startScroll;
      const rotations = Math.floor(pixelsScrolled / 1000);
      const rotation = rotations * rotationAngle + (pixelsScrolled % 1000) * (rotationAngle / 1000);
      const rotationIcons = `rotate(${rotation}deg)`;
      // Apply the rotation angle to the circle element
      imageRef.current.style.transform = rotationIcons;
    }
    imageRefOuter.current.style.transform = rotationOuter;
    imageRefInner.current.style.transform = rotationInner;
  };

  const handleNavigate = (scrollPos) => {
    setScrollPosition(scrollPos)
    window.scrollTo({
      top: scrollPos,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if(scrollPosition <= 4000) {
      video1Ref.current.play();
      video1Ref.current.style.visibility = 'visible';

      video2Ref.current.pause();
      video2Ref.current.style.visibility = 'hidden';

      video3Ref.current.pause();
      video3Ref.current.style.visibility = 'hidden';

      video4Ref.current.pause();
      video4Ref.current.style.visibility = 'hidden';

      video5Ref.current.pause();
      video5Ref.current.style.visibility = 'hidden';

      video6Ref.current.pause();
      video6Ref.current.style.visibility = 'hidden';

      video7Ref.current.pause();
      video7Ref.current.style.visibility = 'hidden';
    } else if (scrollPosition > 4000 && scrollPosition <= 5000) {
      video1Ref.current.pause();
      video1Ref.current.style.visibility = 'hidden';

      video2Ref.current.pause();
      video2Ref.current.style.visibility = 'hidden';

      video3Ref.current.pause();
      video3Ref.current.style.visibility = 'hidden';

      video4Ref.current.pause();
      video4Ref.current.style.visibility = 'hidden';

      video5Ref.current.pause();
      video5Ref.current.style.visibility = 'hidden';

      video6Ref.current.pause();
      video6Ref.current.style.visibility = 'hidden';

      video7Ref.current.play();
      video7Ref.current.style.visibility = 'visible';
    } else if (scrollPosition > 5000 && scrollPosition <=9000) {
      video1Ref.current.pause();
      video1Ref.current.style.visibility = 'hidden';

      video2Ref.current.play();
      video2Ref.current.style.visibility = 'visible';

      video3Ref.current.pause();
      video3Ref.current.style.visibility = 'hidden';

      video4Ref.current.pause();
      video4Ref.current.style.visibility = 'hidden';

      video5Ref.current.pause();
      video5Ref.current.style.visibility = 'hidden';

      video6Ref.current.pause();
      video6Ref.current.style.visibility = 'hidden';

      video7Ref.current.pause();
      video7Ref.current.style.visibility = 'hidden';
    } else if (scrollPosition > 9000 && scrollPosition <=13000) {
      video1Ref.current.pause();
      video1Ref.current.style.visibility = 'hidden';

      video2Ref.current.pause();
      video2Ref.current.style.visibility = 'hidden';

      video3Ref.current.play();
      video3Ref.current.style.visibility = 'visible';

      video4Ref.current.pause();
      video4Ref.current.style.visibility = 'hidden';

      video5Ref.current.pause();
      video5Ref.current.style.visibility = 'hidden';

      video6Ref.current.pause();
      video6Ref.current.style.visibility = 'hidden';

      video7Ref.current.pause();
      video7Ref.current.style.visibility = 'hidden';
    } else if (scrollPosition > 13000 && scrollPosition <=17000) {
      video1Ref.current.pause();
      video1Ref.current.style.visibility = 'hidden';

      video2Ref.current.pause();
      video2Ref.current.style.visibility = 'hidden';

      video3Ref.current.pause();
      video3Ref.current.style.visibility = 'hidden';

      video4Ref.current.play();
      video4Ref.current.style.visibility = 'visible';

      video5Ref.current.pause();
      video5Ref.current.style.visibility = 'hidden';

      video6Ref.current.pause();
      video6Ref.current.style.visibility = 'hidden';

      video7Ref.current.pause();
      video7Ref.current.style.visibility = 'hidden';
    } else if (scrollPosition > 17000 && scrollPosition <=20000) {
      video1Ref.current.pause();
      video1Ref.current.style.visibility = 'hidden';

      video2Ref.current.pause();
      video2Ref.current.style.visibility = 'hidden';

      video3Ref.current.pause();
      video3Ref.current.style.visibility = 'hidden';

      video4Ref.current.pause();
      video4Ref.current.style.visibility = 'hidden';

      video5Ref.current.play();
      video5Ref.current.style.visibility = 'visible';

      video6Ref.current.pause();
      video6Ref.current.style.visibility = 'hidden';

      video7Ref.current.pause();
      video7Ref.current.style.visibility = 'hidden';
    } else if (scrollPosition > 20000) {
      video1Ref.current.pause();
      video1Ref.current.style.visibility = 'hidden';

      video2Ref.current.pause();
      video2Ref.current.style.visibility = 'hidden';

      video3Ref.current.pause();
      video3Ref.current.style.visibility = 'hidden';

      video4Ref.current.pause();
      video4Ref.current.style.visibility = 'hidden';

      video5Ref.current.pause();
      video5Ref.current.style.visibility = 'hidden';

      video6Ref.current.play();
      video6Ref.current.style.visibility = 'visible';

      video7Ref.current.pause();
      video7Ref.current.style.visibility = 'hidden';
    }
  }, [scrollPosition])

  const isSpinnerVisible = () => {
    if(scrollPosition > 5000 && scrollPosition <= 20000) {
      return 'visible';
    } else {
      return 'hidden'
    }
  }

  return (
    <div>
      { !everyVideoLoaded && <Loading loadedPercent={loadedPercent} />}
      <video src={intoVid} ref={video1Ref} autoPlay playsInline loop muted alt="intro-video" preload="true" onLoadedData={handleIntroVideoLoaded} className='background-image-1' />

      <video src={secondSecVid} ref={video2Ref} autoPlay playsInline loop muted alt="second-video" preload="true" onLoadedData={handleSecondSecVideoLoaded} className='background-image-1' />

      <video src={thirdSecVid} ref={video3Ref} autoPlay playsInline loop muted alt="third-video" preload="true" onLoadedData={handleThirdSecVideoLoaded} className='background-image-1' />

      <video src={fourthSecVid} ref={video4Ref} autoPlay playsInline loop muted alt="fourth-video" preload="true" onLoadedData={handleFourthVideoLoaded} className='background-image-1' />

      <video src={fifthSecVid} ref={video5Ref} autoPlay playsInline loop muted alt="fifth-video" preload="true" onLoadedData={handleFifthVideoLoaded} className='background-image-1' />

      <video src={fifthSecVid} ref={video6Ref} autoPlay playsInline loop muted alt="fifth-video" preload="true" onLoadedData={handleFifthVideo2Loaded} className='background-image-footer' />

      <video src={video} ref={video7Ref} autoPlay playsInline loop muted alt="gate-video" preload="true" onLoadedData={handleGateVideoLoad}  className='background-image-1' />
      
      <img src={outerFixed} className='outer-fixed' style={{visibility: isSpinnerVisible() }} />

      <div className='spinning-dial'>
        <img
          src={iconSet}
          alt="Spinning Dial"
          ref={imageRef}
          useMap="#image-map"
          className="icon-set"
          style={{visibility: isSpinnerVisible() }}
        />

        <img
          src={hellaWordsOuter}
          className="hella-words-outer"
          ref={imageRefOuter}
          style={{visibility: isSpinnerVisible() }}
        />

        <img
          src={hellaWordsInner}
          className="hella-words-inner"
          ref={imageRefInner}
          style={{visibility: isSpinnerVisible() }}
        />

        <map name="image-map">
          <area alt="" coords="14,150,14" shape="circle" onClick={() => handleNavigate(5001)} />
          <area alt="" coords="25,202,14" shape="circle" onClick={() => handleNavigate(6001)} />
          <area alt="" coords="54,245,14" shape="circle" onClick={() => handleNavigate(7001)} />
          <area alt="" coords="98,275,14" shape="circle" onClick={() => handleNavigate(8001)} />
          <area alt="" coords="150,285,14" shape="circle" onClick={() => handleNavigate(9001)} />
          <area alt="" coords="202,275,14" shape="circle" onClick={() => handleNavigate(10001)} />
          <area alt="" coords="245,245,14" shape="circle" onClick={() => handleNavigate(11001)} />
          <area alt="" coords="275,202,14" shape="circle" onClick={() => handleNavigate(12001)} />
          <area alt="" coords="286,150,14" shape="circle" onClick={() => handleNavigate(13001)} />
          <area alt="" coords="275,98,14" shape="circle" onClick={() => handleNavigate(14001)} />
          <area alt="" coords="245,54,14" shape="circle" onClick={() => handleNavigate(15001)} />
          <area alt="" coords="202,25,14" shape="circle" onClick={() => handleNavigate(16001)} />
          <area alt="" coords="150,15,14" shape="circle" onClick={() => handleNavigate(17001)} />
          <area alt="" coords="98,25,14" shape="circle" onClick={() => handleNavigate(18001)} />
          <area alt="" coords="54,55,14"  shape="circle" onClick={() => handleNavigate(19001)} />
          <area alt="" coords="25,98,14"  shape="circle" onClick={() => handleNavigate(20501)} />
        </map>
      </div>

      <Controller>
        <Scene
          pin
          triggerElement=".landing-section"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="landing-section">
            <LandingSection scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-one-empty"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-one-empty">
            <FirstSectionEmpty scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-one-upper"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-one-upper">
            <FirstSectionUpper scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-one-lower"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-one-lower">
            <FirstSectionLower />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".video-section"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="video-section">
            <VideoComponent scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-two-empty"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-two-empty">
            <SecondSectionEmpty scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-two-upper"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-two-upper">
            <SecondSectionUpper scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-two-lower"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-two-lower">
            <SecondSectionLower />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-two-asset"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-two-asset">
            <SecondSectionAssets scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-three-empty"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-three-empty">
            <ThirdSectionEmpty scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-three-upper"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-three-upper">
            <ThirdSectionUpper scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-three-lower"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-three-lower">
            <ThirdSectionLower />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-three-asset"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-three-asset">
            <ThirdSectionAssets scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-four-empty"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-four-empty">
            <FourthSectionEmpty scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-four-upper"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-four-upper">
            <FourthSectionUpper scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-four-lower"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-four-lower">
            <FourthSectionLower />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-four-asset"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-four-asset">
            <FourthSectionAssets scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-five-empty"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-five-empty">
            <FifthSectionEmpty scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-five-upper"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-five-upper">
            <FifthSectionUpper scrollPosition={scrollPosition} />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-five-lower"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-five-lower">
            <FifthSectionLower />
          </div>
        </Scene>

        <Scene
          pin
          triggerElement=".section-about"
          triggerHook={0}
          offset={0}
          duration={1000}
          // indicators
        >
          <div className="section-about">
            <FooterSectionUpper />
          </div>
        </Scene>
      </Controller>
    </div>
  );
}

export default HomeSection;
