import React from 'react';
import './ThirdSection.css'

function ThirdSectionEmpty(props) {

  const {scrollPosition} = props

  return (
    <div>
      {scrollPosition >= 8900 && <div className="background-3-ol-empty" />}
    </div>
  );
}

export default ThirdSectionEmpty;
