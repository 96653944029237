import React from 'react';
import './FooterSection.css';
import Logo from '../../../assets/footer/logo.png';
import fb from '../../../assets/navbar/Fb.svg';
import discord from '../../../assets/navbar/discord.svg';
import tw from '../../../assets/navbar/Tw.svg';
import waitlist from '../../../assets/navbar/Waitlist.svg';
import AboutUs from '../../../assets/footer/about-us.svg';
import AboutUsMob from '../../../assets/footer/about-us-mobile.svg';
import Divider from '../../../assets/footer/divider.svg';
import DividerRec from '../../../assets/footer/divider-mobile-rec.svg';
import ToTheTop from '../../../assets/footer/to-the-top.svg';
import ComingSoon from '../../../assets/footer/coming-soon.png';
import ComingSoonMob from '../../../assets/common/coming-soon.svg';
import { Link } from 'react-router-dom';

function FooterSectionUpper() {
  const handleToTheTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <div className="background-5-ol-lower" />
      <div className="footer-empty-section hide-on-mobile" />
      <div className="footer-bg">
        <div className="col-lg-7 col-md-9 col-sm-12 mx-auto text-center footer-title-text">
          <h5 style={{ paddingTop: '8vh' }} className="hide-on-mobile">
            EXPERIENCE THE MYTHS, THE WORLD, AND THE <br /> BATTLES OF MINDBORN
            SONS.
          </h5>
          <h5 style={{ paddingTop: '10vh' }} className="hide-on-pc">
            EXPERIENCE THE <br /> MYTHS, THE WORLD, <br /> AND THE BATTLES OF{' '}
            <br /> MINDBORN SONS.
          </h5>

          {/* <img
            src={ComingSoon}
            alt="coming-soon"
            style={{ width: '10vw' }}
            className="hide-on-mobile"
          /> */}
          {/* <a href="https://discord.gg/rUCFKSgc8T" target="_blank" rel="noreferrer">
            <img
              src={Discord}
              alt="discord-icon"
              className="mt-4 footer-discord-icon"
            />
          </a> */}
          <div className="mt-2 d-flex justify-content-center">
            <div className="my-auto">
              <a
                href="https://twitter.com/MindbornSons"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={tw}
                  alt="footer-icon-middle-twitter"
                  className="footer-icon-middle-twitter hide-on-mobile"
                />
              </a>
            </div>

            <div>
              <a
                href="https://discord.gg/rUCFKSgc8T"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={discord}
                  alt="facebook-icon"
                  className="footer-icon-middle-discord hide-on-mobile"
                />
              </a>
            </div>

            <div className="my-auto">
              <a
                href="https://www.facebook.com/MindbornSons"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={fb}
                  alt="footer-icon-middle-fb"
                  className="footer-icon-middle-fb hide-on-mobile"
                />
              </a>
            </div>
          </div>

          <div className="hide-on-pc mt-5">
            {/* <div>
              <img src={ComingSoonMob} alt="coming-soon" />
            </div> */}

            <div className="d-flex justify-content-center mt-4">
              <div className="my-auto">
                <a
                  href="https://twitter.com/MindbornSons"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={tw}
                    alt="footer-icon-middle-twitter"
                    className="footer-icon-middle-twitter"
                  />
                </a>
              </div>

              <div>
                <a
                  href="https://discord.gg/rUCFKSgc8T"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={discord}
                    alt="facebook-icon"
                    className="footer-icon-middle-discord"
                  />
                </a>
              </div>

              <div className="my-auto">
                <a
                  href="https://www.facebook.com/MindbornSons"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={fb}
                    alt="footer-icon-middle-fb"
                    className="footer-icon-middle-fb"
                  />
                </a>
              </div>
            </div>
            <br />
            <a
              href="https://alpha.mindbornsons.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={waitlist} alt="facebook-icon" className="footer-icon" />
            </a>

            <div className="mt-2">
              <img
                src={DividerRec}
                alt="divider-icon"
                className="footer-icon"
              />
            </div>

            <Link to="/about">
              <img
                src={AboutUsMob}
                alt="about-us"
                style={{ width: '100%' }}
                className="mt-2"
              />
            </Link>
          </div>
        </div>

        <div className="footer-bottom-section hide-on-mobile">
          <div className="col-lg-10 mx-auto">
            <div className="d-flex justify-content-between">
              <div className="my-auto">
                <img src={Logo} style={{ width: '150px' }} alt="logo" />
              </div>

              <div className="about-us-button my-auto">
                <Link to="/about">
                  <img
                    src={AboutUs}
                    alt="about-us"
                    style={{ width: '250px' }}
                  />
                </Link>
              </div>

              <div className="my-auto">
                {/* <a href="https://twitter.com/MindbornSons" target="_blank" rel="noreferrer"><img src={tw} alt="facebook-icon" className="footer-icon" /></a>
                <a href="https://www.facebook.com/MindbornSons" target="_blank" rel="noreferrer"><img src={fb} alt="facebook-icon" className="footer-icon" /></a>
                <a href="https://discord.gg/rUCFKSgc8T" target="_blank" rel="noreferrer"><img
                  src={discord}
                  alt="facebook-icon"
                  className="footer-icon"
                /></a> */}
                <a
                  href="https://alpha.mindbornsons.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={waitlist}
                    alt="facebook-icon"
                    className="footer-icon"
                  />
                </a>
              </div>
            </div>

            <img src={Divider} alt="divider-img" style={{ width: '100%' }} />

            <div
              className="d-flex justify-content-between"
              style={{ paddingBottom: '20px', paddingTop: '15px' }}
            >
              <div className="privacy-policy-section-link">
                <Link to="/privacy-policy">
                  <span className="privacy-policy-section hide-on-mobile">
                    Privacy Policy | Terms of Services | Code of Conduct
                  </span>
                </Link>
              </div>

              <div className="copyright-text text-center">
                <span className="copyright-section hide-on-mobile">
                  © MINDBORN SONS {new Date().getFullYear()} |{' '}
                  <a
                    href="https://ninehermits.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NINE HERMITS
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://growstudio.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GROW STUDIO
                  </a>
                </span>
                <br />
                <span className="copyright-section hide-on-mobile">
                  <a
                    href="https://perfectustec.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WEBSITE DEVELOPED BY PERFECTUS
                  </a>
                </span>
              </div>

              <div
                className="to-the-surface-section"
                onClick={() => handleToTheTop()}
              >
                <span>RETURN TO TOP</span>
                <img
                  src={ToTheTop}
                  alt="to-the-top"
                  className="to-the-top-icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="hide-on-pc mobile-footer-bottom">
          <div className="d-flex justify-content-between">
            <div className="privacy-policy-section-link">
              <Link to="/privacy-policy">
                <div className="mobile-nav-privacy-section p-4">
                  Privacy Policy | Terms of Services | Code of Conduct
                </div>
              </Link>
            </div>

            <div className="my-auto">
              <div
                className="to-the-surface-section my-auto"
                onClick={() => handleToTheTop()}
              >
                <span className="small">RETURN TO TOP</span>
                <img
                  src={ToTheTop}
                  alt="to-the-top"
                  style={{
                    width: '20px',
                    marginRight: '15px',
                    marginLeft: '10px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterSectionUpper;
