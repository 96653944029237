import React from 'react';
import sectionOneText from '../../../assets/section-1/section-1-text.png';
import './FirstSection.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

function FirstSectionUpper(props) {
  const { scrollPosition } = props;
  const { _variants, _duration, _type, _delay } = useSelector((state) => state.transitionProps);

  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      {scrollPosition >= 2000 && <div className="background-ol-upper" />}

      <div className="container">
        <div className="container">
          <div className="content-section">
            <motion.div
              animate={inView ? 'visible' : 'hidden'}
              variants={_variants}
              exit="hidden"
              transition={{ duration: _duration, ease: _type, delay: _delay }}
              ref={ref}
            >
              <div className="section-symbol-text first-section-symbol">
                <img src={sectionOneText} alt="sectionOneText" />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstSectionUpper;
