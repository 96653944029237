import React from 'react';
import './SecondSection.css'

function SecondSectionEmpty(props) {

  const {scrollPosition} = props

  return (
    <div>
      {scrollPosition >= 4900 && scrollPosition <= 6100 && <div className='background-2-ol-empty' />}
    </div>
  );
}

export default SecondSectionEmpty;
