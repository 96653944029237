import React from 'react';
import { Card } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Carousel3D from '../../../assets/common/3dCarousel/Carousel3D';
import './SecondSection.css';
import { setActiveIndex } from '../../../Redux/CarsoulActiveIndex';
import { useDispatch } from 'react-redux';

function SecondSectionAssets(props) {
  const { scrollPosition } = props;
  const dispatch = useDispatch();

  const slides = [
    {
      key: uuidv4(),
      content: (
        <Card
          className="carousel-card carousel-card-2-1"
          onClick={() => dispatch(setActiveIndex(0))}
        >
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          className="carousel-card carousel-card-2-2"
          onClick={() => dispatch(setActiveIndex(1))}
        >
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          className="carousel-card carousel-card-2-3"
          onClick={() => dispatch(setActiveIndex(2))}
        >
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
  ];

  const assetDetails = [
    {
      name: 'Jala, the Water',
      desc: (
        <div className="asset-desc">
          <p>Jala is the veins that keep this land alive.</p>
          <p>
            Water connects all lands and beings of Magharesh. Water is also the
            life force of the goddess Mahee.
          </p>
        </div>
      ),
    },
    {
      name: 'Sacred Dwaras',
      desc: (
        <div className="asset-desc">
          <p>
            Sacred Dwaras open to the halls of the hallowed Magharesh temple,
            built to venerate and worship the goddess Mahee and the sages.
            Carved upon the doors is the image of the sacred Na tree, the tree
            of enlightenment.
          </p>
        </div>
      ),
    },
    {
      name: 'Hela Runestones',
      desc: (
        <div className="asset-desc">
          <p>
            The unique symbol of the Helabasa is an ancestral form of the
            indigenous languages. On Magharesh, the Helabasa symbols represent
            the culture it belongs to. They are often engraved on trees, rocks,
            and other surfaces as a written tribute to the Gods and Sages of the
            first Epoch.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div>
      {scrollPosition >= 8000 && <div className="background-2-ol-assets" />}

      <div className="container">
        <div className="content-section-carsoul">
          <div className="container">
            <div className="assets-section">
              <div
                className="col-lg-11 col-md-12 col-sm-12 mx-auto"
              >
                <Carousel3D images={slides} assetDetails={assetDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSectionAssets;
