import React from 'react';
import designElement from '../../../assets/common/design-element.webp';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

function ThirdSectionLower() {
  const { _variants, _duration, _type, _delay } = useSelector(
    (state) => state.transitionProps
  );
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      <div className="background-3-ol-lower" />
      <div className="container">
        <div className="content-section">
          <div className="container">
            <motion.div
              animate={inView ? 'visible' : 'hidden'}
              variants={_variants}
              exit="hidden"
              transition={{ duration: _duration, ease: _type, delay: _delay }}
              ref={ref}
            >
              <div className="section-lower-text">
                <div className="d-flex">
                  <div className="my-auto section-design-element">
                    <img
                      src={designElement}
                      alt="design-element"
                      className="design-element"
                    />
                  </div>
                  <div className="my-auto">
                    <h4 className="section-sub-topic">Ancient gods</h4>
                    <h1 className="section-title">
                      Our land was born of the gods
                    </h1>
                    <div className="col-lg-10 p-0">
                      <p className="section-description">
                        Legends of old tell the origin of Magharesh, how a god
                        gave birth to this beloved land.
                      </p>

                      <p className="section-description">
                        As Maghar looked upon humanity from his throne in the
                        Neylah Valley, he came to love them and their potential
                        for greatness. He had in mind an oasis, a sanctuary for
                        everything that’s good in mortals.
                      </p>

                      <p className="section-description">
                        He raised his hands once and there rose Magharesh from
                        the earth, the greatest of all land.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSectionLower;
