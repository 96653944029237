import React from 'react';
import Carousel from 'react-spring-3d-carousel';
import leftArrow from './left-arrow.svg';
import rightArrow from './right-arrow.svg';
import './Carousel3D.css';
import designElement from '../../../assets/common/design-element-asset.png';
import centerIco from '../../../assets/common/3dCarousel/center-icons.svg';
import leftIco from '../../../assets/common/3dCarousel/left-mob.svg';
import rightIco from '../../../assets/common/3dCarousel/right-mob.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveIndex } from '../../../Redux/CarsoulActiveIndex';

let xDown = 0;
let yDown = 0;

function Carousel3D(props) {
  const { images, assetDetails } = props;
  const dispatch = useDispatch()
  const { index } = useSelector((state) => state.activeIndex);

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        handleNext()
      } else {
        /* right swipe */
        handleBack()
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  const handleNext = () => {
    if (index < images.length - 1) {
      dispatch(setActiveIndex(index + 1))
    } else {
      dispatch(setActiveIndex(0))
    }
  };

  const handleBack = () => {
    if (index >= 1) {
      dispatch(setActiveIndex(index - 1))
    } else {
      dispatch(setActiveIndex(images.length - 1))
    }
  };

  const getAssetName = () => {
    return assetDetails[index]?.name;
  };

  const getDescription = () => {
    return assetDetails[index]?.desc;
  };

  return (
    <div className='col-lg-10 col-md-11' onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}>
      <div className="d-flex">
        <div className="hide-on-mobile my-auto">
          <img
            src={leftArrow}
            alt="left-arrow"
            style={{ cursor: 'pointer', width: '5vw' }}
            onClick={() => {
              handleBack();
            }}
          />
        </div>
        <div className="mx-auto carousel-section">
          <Carousel slides={images} offsetRadius={2} goToSlide={index} />

          <div className="d-flex justify-content-between">
            <img
              src={leftIco}
              alt="left-icon-mob"
              className="mobile-carousel-icons hide-on-pc"
              onClick={() => {
                handleBack();
              }}
            />
            <img
              src={centerIco}
              alt="center-icon-mob"
              className="mobile-carousel-center-icons hide-on-pc"
            />
            <img
              src={rightIco}
              alt="right-icon-mob"
              className="mobile-carousel-icons hide-on-pc"
              onClick={() => handleNext()}
            />
          </div>
        </div>
        <div className="hide-on-mobile my-auto">
          <img
            src={rightArrow}
            alt="right-arrow"
            style={{ cursor: 'pointer', width: '5vw' }}
            onClick={() => handleNext()}
          />
        </div>
      </div>

      <div className="assets-desc">
        <div>
          <div className="text-center" data-aos="fade-in">
            <h1 className="section-title">{getAssetName()}</h1>
            <img
              src={designElement}
              alt="design-element"
              data-aos="fade-in"
              className="design-element-asset"
            />
            <p className="section-description">{getDescription()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel3D;
