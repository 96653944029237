import React from 'react';
import './LandingSection.css';
import landingLogo from '../../../assets/landing/logo.webp';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import waitlist from '../../../assets/navbar/Waitlist.svg';

function LandingSection(props) {
  const { scrollPosition } = props;

  const { _variants, _duration, _type, _delay } = useSelector(
    (state) => state.transitionProps
  );

  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      {scrollPosition >= 0 && scrollPosition <= 1000 && (
        <div className="background-ol-landing" />
      )}

      <motion.div
        animate={inView ? 'visible' : 'hidden'}
        variants={_variants}
        exit="hidden"
        transition={{ duration: _duration, ease: _type, delay: _delay }}
        ref={ref}
      >
        <div className="center text-center">
          <img src={landingLogo} alt="landing-logo" className="landing-logo" />

          <br />

          <a
            href="https://alpha.mindbornsons.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={waitlist}
              alt="wait-list"
              className="mt-3"
              style={{ marginLeft: '-3px' }}
            />
          </a>
        </div>
      </motion.div>
    </div>
  );
}

export default LandingSection;
