import React from 'react';
import designElement from '../../assets/common/design-element-asset.png';
import nvidia from '../../assets/about-section/nvidia.png';
import avalabs from '../../assets/about-section/avalabs.png';
import solsten from '../../assets/about-section/solsten.png';
import consensys from '../../assets/about-section/consensys.png';
import naavik from '../../assets/about-section/naavik.png';
import unix from '../../assets/about-section/unix.png';
import hathora from '../../assets/about-section/hathora.png';
import { Row, Col } from 'reactstrap';
import Logo from '../../assets/footer/logo.png';
import fb from '../../assets/navbar/Fb.svg';
import discord from '../../assets/navbar/discord.svg';
import tw from '../../assets/navbar/Tw.svg';
import waitlist from '../../assets/navbar/Waitlist.svg';
import Divider from '../../assets/footer/divider.svg';
import ToTheTop from '../../assets/footer/to-the-top.svg';
import { Link } from 'react-router-dom';

function TrustedPartners() {
  const handleToTheTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <div className="container trusted-partners-section">
        <div
          className="text-center trusted-partners-content"
          data-aos="fade-in"
        >
          <h1 className="section-title">Trusted Partners</h1>
          <img
            src={designElement}
            alt="design-element"
            style={{ width: '200px', marginBottom: '10px' }}
            className="text-center"
          />

          <div className="mt-4 hide-on-mobile">
            <Row>
              <Col
                xxl="4"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                xs="12"
                className="mt-5"
              >
                <img
                  src={nvidia}
                  alt="nvidia"
                  className="mt-5"
                  style={{ width: '150px' }}
                />
                <br />

                <img
                  src={avalabs}
                  alt="nvidia"
                  className="mt-5"
                  style={{ width: '100px' }}
                />
              </Col>

              <Col xxl="4" xl="4" lg="4" md="4" sm="12" xs="12">
                <img
                  src={hathora}
                  alt="nvidia"
                  className="mt-5"
                  style={{ width: '150px' }}
                />
                <br />

                <img
                  src={consensys}
                  alt="nvidia"
                  className="mt-5"
                  style={{ width: '170px' }}
                />
                <br />

                <img
                  src={naavik}
                  alt="nvidia"
                  className="mt-5"
                  style={{ width: '150px' }}
                />
              </Col>

              <Col
                xxl="4"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                xs="12"
                className="mt-5"
              >
                <img
                  src={solsten}
                  alt="nvidia"
                  className="mt-5"
                  style={{ width: '150px' }}
                />
                <br />

                <img
                  src={unix}
                  alt="nvidia"
                  className="mt-5"
                  style={{ width: '150px' }}
                />
              </Col>
            </Row>
          </div>

          <div className="hide-on-pc mb-5" data-aos="fade-in">
            <img
              src={nvidia}
              alt="nvidia"
              className="partner-mobile"
              style={{ width: '120px' }}
            />
            <br />
            <img
              src={solsten}
              alt="nvidia"
              className="partner-mobile"
              style={{ width: '100px' }}
            />
            <br />
            <img
              src={hathora}
              alt="nvidia"
              className="partner-mobile"
              style={{ width: '120px' }}
            />
            <br />
            <img
              src={consensys}
              alt="nvidia"
              className="partner-mobile"
              style={{ width: '150px' }}
            />
            <br />
            <img
              src={avalabs}
              alt="nvidia"
              className="partner-mobile"
              style={{ width: '70px' }}
            />
            <br />
            <img
              src={naavik}
              alt="nvidia"
              className="partner-mobile"
              style={{ width: '100px' }}
            />
            <br />
            <img
              src={unix}
              alt="nvidia"
              className="partner-mobile"
              style={{ width: '100px' }}
            />
            <br />
          </div>
        </div>

        <div className="footer-bottom-section hide-on-mobile">
          <div className="col-lg-10 mx-auto">
            <div
              className="d-flex justify-content-between"
              style={{ paddingTop: '20px' }}
            >
              <div className="my-auto">
                <img src={Logo} style={{ width: '150px' }} alt="logo" />
              </div>

              <div className="my-auto">
                <a
                  href="https://twitter.com/MindbornSons"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={tw} alt="facebook-icon" className="footer-icon" />
                </a>
                <a
                  href="https://www.facebook.com/MindbornSons"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fb} alt="facebook-icon" className="footer-icon" />
                </a>

                <a
                  href="https://discord.gg/rUCFKSgc8T"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={discord}
                    alt="facebook-icon"
                    className="footer-icon"
                  />
                </a>

                <a
                  href="https://alpha.mindbornsons.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={waitlist}
                    alt="facebook-icon"
                    className="footer-icon"
                  />
                </a>
              </div>
            </div>

            <img src={Divider} alt="divider-img" style={{ width: '100%' }} />

            <div
              className="d-flex justify-content-between"
              style={{ paddingBottom: '20px', paddingTop: '15px' }}
            >
              <div className="privacy-policy-section-link">
                <Link to="/privacy-policy">
                  <span className="privacy-policy-section hide-on-mobile">
                    Privacy Policy | Terms of Services | Code of Conduct{' '}
                  </span>
                </Link>
              </div>

              <div className="copyright-text text-center">
                <span className="copyright-section hide-on-mobile">
                  © MINDBORN SONS {new Date().getFullYear()} |{' '}
                  <a
                    href="https://ninehermits.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NINE HERMITS
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://growstudio.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GROW STUDIO
                  </a>
                </span>
                <br />
                <span className="copyright-section hide-on-mobile">
                  <a
                    href="https://perfectustec.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WEBSITE DEVELOPED BY PERFECTUS
                  </a>
                </span>
              </div>

              <div
                className="to-the-surface-section"
                onClick={() => handleToTheTop()}
              >
                <span>RETURN TO TOP</span>
                <img
                  src={ToTheTop}
                  alt="to-the-top"
                  className="to-the-top-icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="hide-on-pc mobile-footer-bottom">
          <div className="d-flex justify-content-between">
            <div className="privacy-policy-section-link">
              <Link to="/privacy-policy">
                <div className="mobile-nav-privacy-section p-4">
                  Privacy Policy | Terms of Services | Code of Conduct
                </div>
              </Link>
            </div>

            <div className="my-auto">
              <div
                className="to-the-surface-section my-auto"
                onClick={() => handleToTheTop()}
              >
                <span className="small">RETURN TO TOP</span>
                <img
                  src={ToTheTop}
                  alt="to-the-top"
                  style={{
                    width: '20px',
                    marginRight: '15px',
                    marginLeft: '10px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrustedPartners;
