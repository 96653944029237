import React, { useEffect } from 'react';
import designElement from './assets/common/design-element-asset.png';
import backIcon from './assets/about-section/back.svg';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import Logo from './assets/footer/logo.png';
import fb from './assets/navbar/Fb.svg';
import discord from './assets/navbar/discord.svg';
import tw from './assets/navbar/Tw.svg';
import waitlist from './assets/navbar/Waitlist.svg';
import Divider from './assets/footer/divider.svg';
import ToTheTop from './assets/footer/to-the-top.svg';
import { Link } from 'react-router-dom';

function Privacy() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleToTheTop();
  }, []);

  const handleToTheTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // set the duration of the animation
      easing: 'ease-in', // set the easing function to use
      delay: 200, // set the delay (in milliseconds) before the animation starts
    });
  }, []);

  return (
    <div>
      <div alt="about-section" className="background-image-privacy" />
      <div className="background-ol-privacy" />

      <div className="container">
        <div className="about-us-section">
          <div className="container">
            <div
              className="d-flex"
              data-aos="fade-in"
              style={{ cursor: 'pointer' }}
              onClick={() => handleBack()}
            >
              <img
                src={backIcon}
                alt="back-icon"
                className="back-button hide-on-mobile"
              />
              <span className="back-text hide-on-mobile">Back</span>
            </div>

            <div className="text-center" data-aos="fade-in">
              <div>
                <h1 className="section-title">Introduction</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  Mindborn Sons is committed to protecting the privacy of its
                  users. This Privacy Policy explains our practices regarding
                  the collection, use, and disclosure of information that we
                  receive when you use our website and services. By accessing
                  our website, you consent to the collection, use, and
                  disclosure of your information in accordance with this Privacy
                  Policy.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Collection of Information</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  We collect information from you when you register to use our
                  services or when you provide information through our website.
                  The information we collect may include your name, email
                  address, and other personal information. We may also collect
                  non-personal information, such as your IP address, browser
                  type, and operating system.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Use of Information</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  We may use the information we collect to provide you with the
                  services and features of our website, to improve our website
                  and services, to communicate with you, to protect our rights
                  and property, and to comply with legal obligations.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Disclosure of Information</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  We may disclose the information we collect to third-party
                  service providers who assist us in providing our services,
                  such as payment processors and customer support providers. We
                  may also disclose the information we collect to law
                  enforcement or government officials in response to a subpoena
                  or court order, or when we believe it is necessary to protect
                  our rights, property, or safety or that of others.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Cookies</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  We use cookies to collect information about your use of our
                  website, to improve your user experience, and to personalize
                  your experience on our website. Cookies are small data files
                  that are stored on your computer or mobile device. You can
                  control the use of cookies through your browser settings.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Security</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  We take reasonable measures to protect your information from
                  unauthorized access, use, and disclosure. However, no method
                  of transmission over the internet or electronic storage is
                  100% secure.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Third-Party Links</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  Our website may contain links to third-party websites. We are
                  not responsible for the privacy practices or content of those
                  websites. We encourage you to review the privacy policies of
                  those websites before providing them with any personal
                  information.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Children’s Privacy</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  Our website and services are not intended for children under
                  the age of 13. We do not knowingly collect personal
                  information from children under the age of 13. If we become
                  aware that we have collected personal information from a child
                  under the age of 13, we will take steps to remove that
                  information from our systems.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Changes to Privacy Policy</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  We reserve the right to modify this Privacy Policy at any
                  time. If we make material changes to this Privacy Policy, we
                  will notify you by email or by posting a notice on our
                  website.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="section-title">Contact Us</h1>
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px', marginBottom: '10px' }}
                  className="text-center"
                />

                <p className="section-description mt-3">
                  If you have any questions about this Privacy Policy, please
                  contact us at{' '}
                  <a
                    href="mailto:support@mindbornsons.com"
                    style={{ textDecoration: 'none', color: '#fdc775' }}
                  >
                    support@mindbornsons.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="footer-bottom-section-privacy hide-on-mobile mt-5">
            <div className="col-lg-10 mx-auto">
              <div
                className="d-flex justify-content-between"
                style={{ paddingTop: '20px' }}
              >
                <div className="my-auto">
                  <img src={Logo} style={{ width: '150px' }} alt="logo" />
                </div>

                <div className="my-auto">
                  <a
                    href="https://twitter.com/MindbornSons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={tw} alt="facebook-icon" className="footer-icon" />
                  </a>
                  <a
                    href="https://www.facebook.com/MindbornSons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={fb} alt="facebook-icon" className="footer-icon" />
                  </a>

                  <a
                    href="https://discord.gg/rUCFKSgc8T"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={discord}
                      alt="facebook-icon"
                      className="footer-icon"
                    />
                  </a>

                  <a
                    href="https://alpha.mindbornsons.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={waitlist}
                      alt="facebook-icon"
                      className="footer-icon"
                    />
                  </a>
                </div>
              </div>

              <img src={Divider} alt="divider-img" style={{ width: '100%' }} />

              <div
                className="d-flex justify-content-between"
                style={{ paddingBottom: '20px', paddingTop: '15px' }}
              >
                <div className="privacy-policy-section-link">
                  <Link to="/privacy-policy">
                    <span className="privacy-policy-section hide-on-mobile">
                      Privacy Policy | Terms of Services | Code of Conduct{' '}
                    </span>
                  </Link>
                </div>

                <div className="copyright-text text-center">
                  <span className="copyright-section hide-on-mobile">
                    © MINDBORN SONS {new Date().getFullYear()} |{' '}
                    <a
                      href="https://ninehermits.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      NINE HERMITS
                    </a>{' '}
                    |{' '}
                    <a
                      href="https://growstudio.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GROW STUDIO
                    </a>
                  </span>
                  <br />
                  <span className="copyright-section hide-on-mobile">
                    <a
                      href="https://perfectustec.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WEBSITE DEVELOPED BY PERFECTUS
                    </a>
                  </span>
                </div>

                <div
                  className="to-the-surface-section"
                  onClick={() => handleToTheTop()}
                >
                  <span>RETURN TO TOP</span>
                  <img
                    src={ToTheTop}
                    alt="to-the-top"
                    className="to-the-top-icon"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="hide-on-pc mobile-footer-bottom-privacy mt-5">
            <div className="d-flex justify-content-between">
              <div className="privacy-policy-section-link">
                <Link to="/privacy-policy">
                  <div className="mobile-nav-privacy-section p-4">
                    Privacy Policy | Terms of Services | Code of Conduct
                  </div>
                </Link>
              </div>

              <div className="my-auto">
                <div
                  className="to-the-surface-section my-auto"
                  onClick={() => handleToTheTop()}
                >
                  <span className="small">RETURN TO TOP</span>
                  <img
                    src={ToTheTop}
                    alt="to-the-top"
                    style={{
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
