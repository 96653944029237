import React from 'react';
import './FourthSection.css'

function FourthSectionEmpty(props) {

  const {scrollPosition} = props

  return (
    <div>
      { scrollPosition >= 12900 && <div className='background-4-ol-empty' />}
    </div>
  );
}

export default FourthSectionEmpty;
