import React from 'react';
import sectionTwoText from '../../../assets/section-2/section-2-text.png';
import './SecondSection.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

function SecondSectionUpper(props) {

  const { _variants, _duration, _type, _delay } = useSelector((state) => state.transitionProps);
  const { scrollPosition } = props;

  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      {scrollPosition >= 6000 && <div className="background-2-ol-upper" />}
      
        <div className="container">
          <div className="content-section">
            <motion.div
              animate={inView ? 'visible' : 'hidden'}
              variants={_variants}
              exit="hidden"
              transition={{ duration: _duration, ease: _type, delay: _delay }}
              ref={ref}
            >
              <div className="section-symbol-text">
                <img
                  src={sectionTwoText}
                  alt="sectionTwoText"
                />
              </div>
              </motion.div>
          </div>
        </div>
    </div>
  );
}

export default SecondSectionUpper;
