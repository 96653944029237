import { Spin, Progress } from 'antd'
import React from 'react'
import './Loading.css'
import logo from '../../assets/common/loading-logo.webp'
import { LoadingOutlined } from '@ant-design/icons';

function Loading(props) {

  const {loadedPercent} = props

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: '#ECDBB2'
      }}
      spin
    />
  );

  return (
    <div className='loading-screen'>
      <div className='center'>
        <div className='text-center'>
          <img src={logo} style={{width: '150px'}} />
        </div>
        <div className='text-center mt-4'>
          <Spin indicator={antIcon} />
        </div>
        
        <div className='mt-4 progress-section'>
          <div>
            <Progress 
              percent={loadedPercent} 
              status="active" 
              showInfo={false} 
              size="small"
              style={{width: '300px'}}
              strokeColor={{
                '0%': '#000',
                '100%': '#FFC977',
              }}
            />
          </div>
        </div>

        <div className='mt-4 text-center'>
          <p className='loaded-percentage'>{loadedPercent}</p>
        </div>
      </div>
    </div>
  )
}

export default Loading