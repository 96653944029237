import React from 'react';
import { Card } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Carousel3D from '../../../assets/common/3dCarousel/Carousel3D';
import { setActiveIndex } from '../../../Redux/CarsoulActiveIndex';
import { useDispatch } from 'react-redux';

function FourthSectionAssets(props) {
  const { scrollPosition } = props;
  const dispatch = useDispatch()

  const slides = [
    {
      key: uuidv4(),
      content: (
        <Card className="carousel-card carousel-card-4-1" onClick={() => dispatch(setActiveIndex(0))}>
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card className="carousel-card carousel-card-4-2" onClick={() => dispatch(setActiveIndex(1))}>
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card className="carousel-card carousel-card-4-3" onClick={() => dispatch(setActiveIndex(2))}>
          <Card className="carousel-card-inner" /> 
        </Card>
      ),
    },
  ];

  const assetDetails = [
    {
      name: 'Surya Crystals',
      desc: (
        <div className="asset-desc">
          <p>
            The Surya Crystal is used to absorb and hold the energy of the sun.
          </p>
          <p>
            This is ancient technology that has been passed down through
            generations in Magharesh.
          </p>
        </div>
      ),
    },
    {
      name: 'Na Tree',
      desc: (
        <div className="asset-desc">
          <p>
            It is believed that the red tree or the Na tree holds mystical power
            that can enlighten or heal those who can tap into its essence.
          </p>
          <p>
            Magharesh is the birthplace of this graceful and sacred tree that
            manifested from the magic of Maghar and Mahee.
          </p>
        </div>
      ),
    },
    {
      name: 'Arch of Val Vein',
      desc: (
        <div className="asset-desc">
          <p>
            The Val Vein is a testament of how mortals and nature can coexist
            but supporting each other.
          </p>
          <p>
            The Val Vein is a plant native to Magharesh, which along with
            alchemy, is used by the Magharesh people to build and hold
            structures in place.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      {scrollPosition >= 16000 && <div className="background-4-ol-assets" />}
      <div className="content-section-carsoul">
        <div className="container">
          <div className="assets-section">
            <div
              className="col-lg-11 col-md-12 col-sm-12 mx-auto"
            >
              <Carousel3D images={slides} assetDetails={assetDetails} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FourthSectionAssets;
