import React from 'react';
import designElement from '../../../assets/common/design-element.webp';
import './FirstSection.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

function FirstSectionLower() {
  const { _variants, _duration, _type, _delay } = useSelector(
    (state) => state.transitionProps
  );
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      <div className="background-ol-lower" />
      <div className="container">
        <div className="content-section">
          <div className="container">
            <motion.div
              animate={inView ? 'visible' : 'hidden'}
              variants={_variants}
              exit="hidden"
              transition={{ duration: _duration, ease: _type, delay: _delay }}
              ref={ref}
            >
              <div className="section-lower-text">
                <div className="d-flex">
                  <div className="my-auto section-design-element">
                    <img
                      src={designElement}
                      alt="design-element"
                      className="design-element"
                    />
                  </div>
                  <div className="my-auto">
                    <h4 className="section-sub-topic">EPIC WORLD. EPIC GAME</h4>
                    <h1 className="section-title">THIS IS MAGHARESH</h1>
                    <div className="p-0">
                      <p className="section-description">
                        People of Magharesh have lived in peace and harmony with
                        the land. The land and our gods have always sustained
                        us, watched over us, and protected us.
                      </p>

                      <p className="section-description">
                        But no longer...A darkness unknown spreads over the
                        land. It consumes everything.
                      </p>

                      <p className="section-description">
                        So who are You? Are you one with that darkness, or are
                        you our liberator?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstSectionLower;
