import React from 'react';
import designElement from '../../../assets/common/design-element-asset.png';
import './VideoComponent.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

function VideoComponent(props) {
  const { _variants, _duration, _type, _delay } = useSelector((state) => state.transitionProps);
  const { scrollPosition } = props;

  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      {scrollPosition >= 3900 && <div className="background-ol-gate" />}
      <div className="background-video-ol-lower" />

      <div className="container">
        <div className="content-section-video-section">
          <div className="container">
            <motion.div
              animate={inView ? 'visible' : 'hidden'}
              variants={_variants}
              exit="hidden"
              transition={{ duration: _duration, ease: _type, delay: _delay }}
              ref={ref}
            >
              <div className="section-lower-text-video text-center">
                <img
                  src={designElement}
                  alt="design-element"
                  style={{ width: '200px' }}
                />
                <p className="section-description mt-3">
                  "Beyond this threshold,
                  <br /> The story of our past is told.
                  <br /> Enter warriors, faithful and bold,
                  <br /> Heed the calls of our gods of old."
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoComponent;
