import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Drawer } from 'antd';
import Home from './Home';
import About from './About';
import logo from './assets/navbar/logo.png';
import fb from './assets/navbar/Fb.svg';
import discord from './assets/navbar/discord.svg';
import tw from './assets/navbar/Tw.svg';
import waitlist from './assets/navbar/Waitlist.svg';
import menuIcon from './assets/navbar/menu-icon.svg';
import closeIcon from './assets/navbar/close-icon.svg';
import menuBg from './assets/navbar/hella-words.svg';
import aboutUsMob from './assets/navbar/about-us-mob.svg';
import { useSelector } from 'react-redux';
import music from './assets/common/music.mp3';
import soundOn from './assets/common/sound-on.svg';
import soundOff from './assets/common/sound-off.svg';
import designElement from './assets/common/highlighter.svg';
import Privacy from './Privacy';
import ComingSoonMob from './assets/common/coming-soon.svg';

function LayoutView() {
  const { Header, Content } = Layout;
  const navigate = useNavigate();
  const location = useLocation();

  const audioRef = useRef();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [playIconVisibility, setPlayIconVisibility] = useState('hidden');
  const [pauseIconVisibility, setPauseIconVisibility] = useState('visible');

  // mobile menu related variables
  const [menuOpened, setMenuOpened] = useState(false);

  const { loaded } = useSelector((state) => state.videoLoaded);

  // active classes for navbar - pc
  const [homeClass, setHomeClass] = useState('');
  const [firstClass, setFirstClass] = useState('');
  const [secondClass, setSecondClass] = useState('');
  const [thirdClass, setThirdClass] = useState('');
  const [fourthClass, setFourthClass] = useState('');

  // active classes for navbar - mobile
  const [homeClassMob, setHomeClassMob] = useState('');
  const [firstClassMob, setFirstClassMob] = useState('');
  const [secondClassMob, setSecondClassMob] = useState('');
  const [thirdClassMob, setThirdClassMob] = useState('');
  const [fourthClassMob, setFourthClassMob] = useState('');

  const [scrollPosition, setScrollPosition] = useState(null);

  const handleNavigate = (scrollPos) => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    window.scrollTo({
      top: scrollPos,
      behavior: 'smooth',
    });
    setScrollPosition(scrollPos);
    setMenuOpened(false);
  };

  const handleAboutUsMobile = () => {
    navigate('/about');
    setMenuOpened(false);
  };

  const handleToHome = () => {
    navigate('/');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handlePlayAudio = () => {
    if (audioRef.current) {
      setPauseIconVisibility('hidden');
      setPlayIconVisibility('visible');
      setIsAudioPlaying(true);
      audioRef.current.play();
    }
  };

  const handleStopAudio = () => {
    if (audioRef.current) {
      setPlayIconVisibility('hidden');
      setPauseIconVisibility('visible');
      setIsAudioPlaying(false);
      audioRef.current.pause();
    }
  };

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      setScrollPosition(scrollPosition);
      if (location.pathname === '/') {
        if (scrollPosition <= 1000) {
          setHomeClass('');
          setFirstClass('');
          setSecondClass('');
          setThirdClass('');
          setFourthClass('');

          setHomeClassMob('');
          setFirstClassMob('');
          setSecondClassMob('');
          setThirdClassMob('');
          setFourthClassMob('');
        } else if (scrollPosition > 1000 && scrollPosition <= 4000) {
          setHomeClass('active');
          setFirstClass('');
          setSecondClass('');
          setThirdClass('');
          setFourthClass('');

          setHomeClassMob('navbar-link-mobile-active');
          setFirstClassMob('');
          setSecondClassMob('');
          setThirdClassMob('');
          setFourthClassMob('');
        } else if (scrollPosition > 4000 && scrollPosition <= 9000) {
          setHomeClass('');
          setFirstClass('active');
          setSecondClass('');
          setThirdClass('');
          setFourthClass('');

          setHomeClassMob('');
          setFirstClassMob('navbar-link-mobile-active');
          setSecondClassMob('');
          setThirdClassMob('');
          setFourthClassMob('');
        } else if (scrollPosition > 9000 && scrollPosition <= 13000) {
          setHomeClass('');
          setFirstClass('');
          setSecondClass('active');
          setThirdClass('');
          setFourthClass('');

          setHomeClassMob('');
          setFirstClassMob('');
          setSecondClassMob('navbar-link-mobile-active');
          setThirdClassMob('');
          setFourthClassMob('');
        } else if (scrollPosition > 13000 && scrollPosition <= 17000) {
          setHomeClass('');
          setFirstClass('');
          setSecondClass('');
          setThirdClass('active');
          setFourthClass('');

          setHomeClassMob('');
          setFirstClassMob('');
          setSecondClassMob('');
          setThirdClassMob('navbar-link-mobile-active');
          setFourthClassMob('');
        } else if (scrollPosition > 17000) {
          setHomeClass('');
          setFirstClass('');
          setSecondClass('');
          setThirdClass('');
          setFourthClass('active');

          setHomeClassMob('');
          setFirstClassMob('');
          setSecondClassMob('');
          setThirdClassMob('');
          setFourthClassMob('navbar-link-mobile-active');
        }
      } else {
        setHomeClass('');
        setFirstClass('');
        setSecondClass('');
        setThirdClass('');
        setFourthClass('');

        setHomeClassMob('');
        setFirstClassMob('');
        setSecondClassMob('');
        setThirdClassMob('');
        setFourthClassMob('');
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  return (
    <Layout
      className="layout"
      style={{ background: 'transparent', overflowX: 'hidden' }}
    >
      <audio src={music} ref={audioRef} autoPlay loop preload="true" />
      {loaded && (
        <>
          <Drawer
            placement="left"
            width={'100vw'}
            onClose={() => setMenuOpened(false)}
            open={menuOpened}
            closable={false}
          >
            <img src={menuBg} alt="menu-bg" className="mobile-menu-bg" />
            <div className="mobile-menu-bg-ol">
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <div className="mt-1 my-auto">
                    <img
                      src={logo}
                      alt="navbar-logo"
                      style={{ width: '120px' }}
                    />
                  </div>

                  <div className="my-auto">
                    <img
                      src={closeIcon}
                      alt="navbar-logo"
                      style={{ width: '30px', cursor: 'pointer' }}
                      onClick={() => setMenuOpened(false)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div>
                  <div className={homeClassMob}>
                    <p
                      className="navbar-link-mobile text-center p-2"
                      onClick={() => handleNavigate(3001)}
                    >
                      MAGHARESH
                    </p>
                  </div>

                  <div className={firstClassMob}>
                    <p
                      className="navbar-link-mobile text-center p-2"
                      onClick={() => handleNavigate(7001)}
                    >
                      EPIC MYTH
                    </p>
                  </div>

                  <div className={secondClassMob}>
                    <p
                      className="navbar-link-mobile text-center p-2"
                      onClick={() => handleNavigate(11001)}
                    >
                      ANCIENT GODS
                    </p>
                  </div>

                  <div className={thirdClassMob}>
                    <p
                      className="navbar-link-mobile text-center p-2"
                      onClick={() => handleNavigate(15001)}
                    >
                      EPIC WORLD
                    </p>
                  </div>

                  <div className={fourthClassMob}>
                    <p
                      className="navbar-link-mobile text-center p-2"
                      onClick={() => handleNavigate(19001)}
                    >
                      THE CALL
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="text-center mt-4">
                <img src={ComingSoonMob} alt="coming-soon" />
              </div> */}

              <div className="mobile-nav-bottom">
                <div
                  className="d-flex justify-content-center"
                  style={{ marginLeft: '5px' }}
                >
                  <img
                    src={soundOff}
                    alt="sound-on"
                    style={{
                      width: '31px',
                      height: '31px',
                      cursor: 'pointer',
                      visibility: pauseIconVisibility,
                      position: 'fixed',
                      marginLeft: '-130px',
                    }}
                    onClick={() => handlePlayAudio()}
                  />

                  <img
                    src={soundOn}
                    alt="sound-off"
                    style={{
                      width: '31px',
                      height: '31px',
                      cursor: 'pointer',
                      visibility: playIconVisibility,
                      position: 'fixed',
                      marginLeft: '-130px',
                    }}
                    onClick={() => handleStopAudio()}
                  />

                  <img
                    src={designElement}
                    alt="seperator"
                    style={{
                      height: '50px',
                      marginLeft: '45px',
                      marginTop: '-10px',
                    }}
                  />

                  <a
                    href="https://twitter.com/MindbornSons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={tw} alt="twitter" className="navbar-button" />
                  </a>
                  <a
                    href="https://www.facebook.com/MindbornSons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={fb} alt="facebook" className="navbar-button" />
                  </a>
                  <a
                    href="https://discord.gg/rUCFKSgc8T"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={discord}
                      alt="discord"
                      className="navbar-button"
                    />
                  </a>
                </div>

                <div className="mt-3 text-center">
                  <a
                    href="https://alpha.mindbornsons.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={waitlist}
                      alt="facebook"
                      className="navbar-button"
                    />
                  </a>
                </div>

                <div className="mobile-nav-bottom-bg">
                  <div className="d-flex justify-content-between">
                    <div className="mobile-nav-copyright-section p-2">
                      © MINDBORN SONS {new Date().getFullYear()} <br />
                      <a
                        href="https://ninehermits.com"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'white' }}
                      >
                        NINE HERMITS
                      </a>{' '}
                      X{' '}
                      <a
                        href="https://growstudio.io"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'white' }}
                      >
                        GROW STUDIO
                      </a>
                      <br />
                      <a
                        href="https://perfectustec.com"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'white' }}
                      >
                        WEBSITE DEVELOPED BY PERFECTUS
                      </a>
                    </div>

                    <div className="my-auto">
                      <img
                        src={aboutUsMob}
                        style={{ marginRight: '10px' }}
                        alt="about-us"
                        onClick={() => handleAboutUsMobile()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
          <div className="fixed-top">
            <div className="navbar-bg">
              <Header
                className="hide-on-mobile"
                style={{ background: 'transparent' }}
              >
                <div className="container-fluid">
                  <div className="d-flex justify-content-between">
                    <div className="mt-3">
                      <img
                        src={logo}
                        className="nav-logo"
                        alt="navbar-logo"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleToHome()}
                      />
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className={homeClass}>
                        <p
                          className="navbar-link text-center"
                          onClick={() => handleNavigate(3001)}
                        >
                          MAGHARESH
                        </p>
                      </div>

                      <div className={firstClass}>
                        <p
                          className="navbar-link text-center"
                          onClick={() => handleNavigate(7001)}
                        >
                          EPIC MYTH
                        </p>
                      </div>

                      <div className={secondClass}>
                        <p
                          className="navbar-link text-center"
                          onClick={() => handleNavigate(11001)}
                        >
                          ANCIENT GODS
                        </p>
                      </div>

                      <div className={thirdClass}>
                        <p
                          className="navbar-link text-center"
                          onClick={() => handleNavigate(15001)}
                        >
                          EPIC WORLD
                        </p>
                      </div>

                      <div className={fourthClass}>
                        <p
                          className="navbar-link text-center"
                          onClick={() => handleNavigate(19001)}
                        >
                          THE CALL
                        </p>
                      </div>
                    </div>

                    <div className="mt-3 d-flex">
                      <img
                        src={soundOff}
                        alt="sound-on"
                        style={{
                          width: '31px',
                          height: '31px',
                          cursor: 'pointer',
                          visibility: pauseIconVisibility,
                          position: 'fixed',
                          marginTop: '18px',
                          marginLeft: '-30px',
                        }}
                        onClick={() => handlePlayAudio()}
                      />

                      <img
                        src={soundOn}
                        alt="sound-off"
                        style={{
                          width: '31px',
                          height: '31px',
                          cursor: 'pointer',
                          visibility: playIconVisibility,
                          position: 'fixed',
                          marginTop: '18px',
                          marginLeft: '-30px',
                        }}
                        onClick={() => handleStopAudio()}
                      />

                      <img
                        src={designElement}
                        alt="seperator"
                        style={{
                          height: '45px',
                          marginLeft: '8px',
                          marginTop: '10px',
                        }}
                      />

                      <a
                        href="https://twitter.com/MindbornSons"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={tw} alt="twitter" className="navbar-button" />
                      </a>
                      <a
                        href="https://www.facebook.com/MindbornSons"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={fb}
                          alt="facebook"
                          className="navbar-button"
                        />
                      </a>

                      <a
                        href="https://discord.gg/rUCFKSgc8T"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={discord}
                          alt="discord"
                          className="navbar-button"
                        />
                      </a>
                      <a
                        href="https://alpha.mindbornsons.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={waitlist}
                          alt="wait-list"
                          className="navbar-button waitlist-button"
                        />
                      </a>

                      {/* <img
                        src={designElement}
                        alt="seperator"
                        style={{
                          height: '45px',
                          marginLeft: '8px',
                          marginTop: '10px',
                        }}
                      />

                      <span className="coming-soon-text">
                        TRIBES COMING SOON
                      </span> */}
                    </div>
                  </div>
                </div>
              </Header>

              <Header
                className="hide-on-pc"
                style={{ background: 'transparent' }}
              >
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="my-auto">
                      <img
                        src={logo}
                        alt="navbar-logo"
                        style={{
                          width: '120px',
                          marginLeft: '-25px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleToHome()}
                      />
                    </div>

                    <div className="my-auto">
                      <img
                        src={menuIcon}
                        alt="navbar-logo"
                        style={{
                          width: '25px',
                          cursor: 'pointer',
                          marginRight: '-25px',
                        }}
                        onClick={() => setMenuOpened(true)}
                      />
                    </div>
                  </div>
                </div>
              </Header>
            </div>
          </div>
        </>
      )}

      <Content>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Home
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
              />
            }
          />
          <Route
            path="/about"
            exact
            element={<About scrollPosition={scrollPosition} />}
          />
          <Route path="/privacy-policy" exact element={<Privacy />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default LayoutView;
