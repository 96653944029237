import React, { useEffect } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import AOS from 'aos';
import EmptySection from './components/AboutSection/EmptySection'
import AboutSection from './components/AboutSection/AboutSection'
import MeetTheTribe from './components/AboutSection/MeetTheTribe'
import TrustedPartners from './components/AboutSection/TrustedPartners'

function About(props) {

  const { scrollPosition } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 1000, // set the duration of the animation
      easing: 'ease-in', // set the easing function to use
      delay: 200, // set the delay (in milliseconds) before the animation starts
    });
  }, []);

  const isVisibleBg = () => {
    if(scrollPosition >= 4000) {
      return 'block'
    } else {
      return 'none'
    }
  }

  return (
    <div>
      <div alt="about-section" className='background-image-about' />

      <div className='background-ol-partners' style={{display: isVisibleBg() }} />
        

      <Controller>
        <Scene
            pin
            triggerElement=".about-section"
            triggerHook={0}
            offset={0}
            duration={1000}
            // indicators
          >
            <div className="about-section">
              <AboutSection scrollPosition={scrollPosition} />
            </div>
          </Scene>

        <Scene
            pin
            triggerElement=".empty-section"
            triggerHook={0}
            offset={0}
            duration={1000}
            // indicators
          >
            <div className="empty-section">
              <EmptySection scrollPosition={scrollPosition} />
            </div>
          </Scene>

          <Scene
            pin
            triggerElement=".meet-section"
            triggerHook={0}
            offset={0}
            duration={1000}
            // indicators
          >
            <div className="meet-section">
              <MeetTheTribe scrollPosition={scrollPosition} />
            </div>
          </Scene>

          <Scene
            pin
            triggerElement=".empty-section-2"
            triggerHook={0}
            offset={0}
            duration={1000}
            // indicators
          >
            <div className="empty-section-2">
              <EmptySection scrollPosition={scrollPosition} />
            </div>
          </Scene>

          <Scene
            pin
            triggerElement=".trusted-section"
            triggerHook={0}
            offset={0}
            duration={1000}
            // indicators
          >
            <div className="trusted-section">
              <TrustedPartners scrollPosition={scrollPosition} />
            </div>
          </Scene>
      </Controller>
    </div>
  )
}

export default About