import React from 'react';
import designElement from '../../../assets/common/design-element.webp';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

function FourthSectionLower() {
  const { _variants, _duration, _type, _delay } = useSelector(
    (state) => state.transitionProps
  );
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <div>
      <div className="background-4-ol-lower" />
      <div className="container">
        <div className="content-section">
          <div className="container">
            <motion.div
              animate={inView ? 'visible' : 'hidden'}
              variants={_variants}
              exit="hidden"
              transition={{ duration: _duration, ease: _type, delay: _delay }}
              ref={ref}
            >
              <div className="section-lower-text">
                <div className="d-flex">
                  <div className="my-auto section-design-element">
                    <img
                      src={designElement}
                      alt="design-element"
                      className="design-element"
                    />
                  </div>
                  <div className="my-auto">
                    <h4 className="section-sub-topic">EPIC WORLD</h4>
                    <h1 className="section-title">This is our prayer</h1>
                    <div className="p-0">
                      <p className="section-description">
                        As the land cries rivers of blood and smoke rises, we
                        raise our arms to our gods. We pray for relief.
                      </p>

                      <p className="section-description">
                        Hear us, o gods. The people of Magharesh know not of
                        warfare. We wield divine faith instead of martial
                        prowess.
                      </p>

                      <p className="section-description">
                        It is our prayer that the gods deliver us from the
                        darkness. May they send heroes to fight with us, for us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FourthSectionLower;
