import React from 'react'
import NHLogo from '../../assets/about-section/9h-logo.png'
import growLogo from '../../assets/about-section/GROWStudio-logo.png'
import designElement from '../../assets/common/design-element-asset.png';
import downArrow from '../../assets/common/down-arrow.svg';
import tribes from '../../assets/about-section/tribes.png'

function MeetTheTribe(props) {

  const {scrollPosition} = props

  return (
    <div className='container'>
        {
          scrollPosition >= 2000 && <div className='background-ol-meet-the-tribe' />
        }
        <div style={{paddingTop: '20vh'}} className="text-center" data-aos="fade-in">
          <h1 className="section-title">Meet the tribe</h1>
          <img
            src={designElement}
            alt="design-element"
            style={{ width: '200px', marginBottom: '10px' }}
            className="text-center"
          />
        </div>

      <div>
        <div className='text-center hide-on-mobile' style={{ paddingTop: '15vh'}}>
          <img src={tribes} alt="tribes-combo" style={{width: '50vw'}} data-aos="fade-in" />
        </div>

        <div className='hide-on-pc text-center' style={{ paddingTop: '10vh'}}>
            <img src={NHLogo} alt="9-hermits-logo" data-aos="fade-in" style={{width: '200px'}} className="mt-4" /><br />
            <img src={growLogo} alt="grow-logo" data-aos="fade-in" style={{width: '250px'}} className="mt-5"  /><br />
            <h6 className='about-section-name' data-aos="fade-in">NINE HERMITS X GROW STUDIO</h6>
        </div>
      </div>

      <div className='down-arrow'>
        <img src={downArrow} alt="down-arrow" />
      </div>
    </div>
  )
}

export default MeetTheTribe