import { configureStore } from "@reduxjs/toolkit";
import videoLoadingReducer from "./LoadedSlice";
import carsoulActiveIndexReducer from './CarsoulActiveIndex'
import TransitionsReducer from "./Transitions";

export const store = configureStore({
  reducer: {
    videoLoaded: videoLoadingReducer,
    activeIndex: carsoulActiveIndexReducer,
    transitionProps: TransitionsReducer
  }
})