import React from 'react';
import { Card } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Carousel3D from '../../../assets/common/3dCarousel/Carousel3D';
import './ThirdSection.css';
import { setActiveIndex } from '../../../Redux/CarsoulActiveIndex';
import { useDispatch } from 'react-redux';

function ThirdSectionAssets(props) {
  const { scrollPosition } = props;
  const dispatch = useDispatch()

  const slides = [
    {
      key: uuidv4(),
      content: (
        <Card className="carousel-card carousel-card-3-1" onClick={() => dispatch(setActiveIndex(0))}>
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card className="carousel-card carousel-card-3-2" onClick={() => dispatch(setActiveIndex(1))}>
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card className="carousel-card carousel-card-3-3" onClick={() => dispatch(setActiveIndex(2))}>
          <Card className="carousel-card-inner" />
        </Card>
      ),
    },
  ];

  const assetDetails = [
    {
      name: 'Mahee',
      desc: (
        <div className='asset-desc'>
          <p>
            Mahee is the Goddess of Earth and Nature. The four Mindborn sons
            Pulastya, Angiras, Bhrigu and Kratu were her mentors who guided her
            into being the creator of forests and its creatures. 
          </p>
          <p>
            Mahee is also the mother of Maghar, her eldest, who also shares her
            love for nature and mortals.
          </p>
        </div>
      ),
    },
    {
      name: 'Griksh',
      desc: (
        <div className='asset-desc'>
          <p>
            Griksh is a shapeshifter and the lord of trees. He is often depicted
            as a creature that is dark in color, horned, with the head of an ape
            and wearing the body of a bear.
          </p>
          <p>
            Griksh is Maghar’s most trusted companion as the two have been
            friends since childhood. Although Griksh is a few centuries older
            than Maghar, both consider each other brothers. 
          </p>
        </div>
      ),
    },
    {
      name: 'Maghar',
      desc: (
        <div className='asset-desc'>
          <p>
            This land was created by Maghar who envisioned that humans and
            nature would coexist in perfect harmony.
          </p>
          <p>
            Maghar is the firstborn son of Mahee, the Goddess of Earth and
            Nature. Like his mother, Maghar could create land and forests at
            will.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      {scrollPosition >= 12000 && <div className="background-3-ol-assets" />}
      <div className="content-section-carsoul">
        <div className="container">
          <div className="assets-section">
            <div
              className="col-lg-11 col-md-12 col-sm-12 mx-auto"
            >
              <Carousel3D images={slides} assetDetails={assetDetails} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSectionAssets;
