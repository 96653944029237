import React from 'react';
import './FifthSection.css'

function FifthSectionEmpty(props) {

  const {scrollPosition} = props

  return (
    <div>
      { scrollPosition >= 16900 && <div className='background-5-ol-empty' />}
    </div>
  );
}

export default FifthSectionEmpty;
